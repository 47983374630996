import React from 'react';
import cx from 'classnames';

import { ModuleWrapper } from '../moduleWrapper';
import { SanityImage } from '@components/sanity/sanityImage';
import { PortableTextBlock } from '@components/sanity/portableTextBlock';

const ImageText = ({ config, image, text, layout, reversed }) => {
  return (
    <ModuleWrapper {...config}>
      <div className='px-gutter grid gap-y-4 md:grid-cols-2 md:gap-x-6 lg:gap-x-10 xl:gap-x-20'>
        <div
          className={cx('md:row-start-1 lg:self-center', {
            'md:col-start-2': layout === 'left',
          })}
        >
          <PortableTextBlock text={text} reversed={reversed} />
        </div>
        <div
          className={cx('rounded-lg overflow-hidden md:row-start-1', {
            'md:col-start-1': layout === 'left',
          })}
        >
          <SanityImage image={image} />
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default ImageText;
